"use client";

import type { UserEntity } from "@/hl-common/types/api/entities/Users";
import Modal from "@/utils/Modal";
import { FlagIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { Button } from "../forms/Button";
import { FeedbackForm } from "./Feedback";

export const FeedbackTinyButton = ({
  iconOnly,
  iconColor,
  user,
}: {
  iconOnly?: boolean;
  iconColor?: string;
  user?: UserEntity;
}) => {
  return (
    <Modal
      content={(closeModal) => (
        <FeedbackForm user={user} closeModal={closeModal} />
      )}
    >
      <Button
        small
        secondary={!iconOnly}
        minimal={iconOnly}
        inline
        className={classNames("w-8 flex items-center justify-center", {
          "rounded-full shadow-sm h-8": !iconOnly,
        })}
        title="Report an issue"
      >
        <FlagIcon
          fill={iconColor ? iconColor : "currentColor"}
          className="h-5 w-5"
        />
      </Button>
    </Modal>
  );
};
